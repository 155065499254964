// ** import Chakra UI
import {
  Container,
  Text,
  Flex,
  Center,
  SimpleGrid,
  useBreakpointValue,
  Spinner,
} from '@chakra-ui/react';

// ** import React
import React, { useEffect } from 'react';

//  ** import third package
import { Link as RouteLink } from 'react-router-dom';

// ** import component
import MItemKatalog from './MItemKatalog';

const MProduct = ({ data, isLoading }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex
      flexDir={'column'}
      w={'full'}
      h={'full'}
      py={useBreakpointValue({ base: '3rem', sm: '5rem' })}
      textAlign={'center'}
      bgColor={'#FFFFFF'}
    >
      <Container maxW="container.xl">
        <Text fontSize={'4xl'} fontWeight={'bold'}>
          Produk Orthophysio
        </Text>
        <Flex
          fontSize={'md'}
          justifyContent={'center'}
          alignItems={'center'}
          color={'#F2994A'}
        >
          <RouteLink to="/">
            <Text mx={'1'}>Home</Text>
          </RouteLink>
          {`>`}
          <Text mx="1" fontWeight={'bold'}>
            Produk
          </Text>
        </Flex>
        <Flex
          flexDir={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {isLoading ? (
            <Center p={10}>
              <Spinner />
            </Center>
          ) : (
            <SimpleGrid
              py="2rem"
              columns={[2, 2, data.length <= 4 ? data.length : 4]}
              spacingX={['initial', '40px']}
              spacingY="20px"
            >
              {data.map((d, idx) => (
                <MItemKatalog data={d} key={idx} />
              ))}
            </SimpleGrid>
          )}
        </Flex>
      </Container>
    </Flex>
  );
};

export default MProduct;
