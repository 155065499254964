/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
// ** import Chakra UI
import {
  VStack,
  Text,
  Flex,
  Spacer,
  Center,
  HStack,
  Box,
  Heading,
  Select,
  useBreakpointValue,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';

// ** import React
import React, { useState } from 'react';

// ** import third package
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Icon } from '@iconify/react';
import { IndonesiaMap } from '../../assets/images';

const MLocationReseller = ({ resellers }) => {
  const [location, setLocation] = useState('default');
  const [reseller, setReseller] = useState({});

  const handleSelectChange = e => {
    setLocation(e.target.value);
    if (e.target.value !== 'default') {
      resellers
        .filter(item => item.province_name === e.target.value)
        .map(reseller => {
          setReseller(reseller);
        });
    } else {
      setReseller({});
    }
  };

  const renderResellerItem = (item, useColorModeValue, useBreakpointValue) => {
    return item.map(itemReseller => {
      return (
        <Box
          key={itemReseller.id}
          bg={useColorModeValue('white', 'gray.800')}
          width={'full'}
          m={2}
        >
          <Box>
            <Text
              textAlign={'center'}
              fontSize={'2xl'}
              textTransform={'capitalize'}
            >
              {itemReseller.account_name}
            </Text>
            <VStack justifyItems={'center'} alignItems={'center'}>
              <Text
                fontSize={'sm'}
                color={'gray.400'}
                textTransform={'capitalize'}
              >
                alamat :
              </Text>
              <Text width={'fit-content'} fontSize={'lg'} color={'gray'}>
                {itemReseller.address}
              </Text>
            </VStack>
            <VStack justifyItems={'center'} alignItems={'center'}>
              <Text
                fontSize={'sm'}
                color={'gray.400'}
                textTransform={'capitalize'}
              >
                Telp/WA :
              </Text>
              <Text width={'fit-content'} fontSize={'lg'} color={'gray'}>
                {itemReseller.phone}
              </Text>
            </VStack>
          </Box>
        </Box>
      );
    });
  };

  return (
    <VStack
      bgColor={'white'}
      px={[10, 30, 100]}
      my={10}
      w="full"
      flexDir={'column'}
      alignItems="start"
      justifyContent="start"
      h={'full'}
    >
      <Flex
        flexDirection={['column', 'column', 'row']}
        justifyContent={'space-between'}
        alignItems={'center'}
        w={'full'}
        pb="10px"
        borderBottom={'1px'}
        borderColor={'gray.300'}
      >
        <Heading
          color={'gray.800'}
          align={['center', 'start', 'start']}
          fontSize={['md', 'lg', '3xl']}
          mb={[5, 0, 0]}
        >
          Temukan Kami di Seluruh Indonesia
        </Heading>
        <Spacer />
        <HStack>
          <Icon
            icon="fa-solid:map-marker-alt"
            color="#F15A25"
            style={{ fontSize: '32px' }}
          />
          <Spacer />
          <Select onChange={e => handleSelectChange(e)}>
            <option value="default">Lokasi Anda</option>
            {resellers.map(item => {
              return (
                <option
                  key={item.id}
                  value={item.province_name}
                  data={item.list_reseller}
                >
                  {item.province_name}
                </option>
              );
            })}
          </Select>
        </HStack>
      </Flex>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        w={'full'}
        minH={['full', '80vh']}
      >
        {location === 'default' ? (
          <Box py={10}>
            <LazyLoadImage src={IndonesiaMap} alt="Dan Abramov" effect="blur" />
          </Box>
        ) : (
          <>
            {reseller.list_reseller.length !== 0 ? (
              <SimpleGrid
                columns={{
                  base: 1,
                  sm: 2,
                  md:
                    reseller.list_reseller.length <= 3
                      ? reseller.list_reseller.length
                      : 3,
                }}
                spacingX={'3rem'}
                spacingY={'5rem'}
                width={'full'}
                style={{
                  overflowX: 'hidden',
                  overflowY: 'visible',
                }}
              >
                {renderResellerItem(
                  reseller.list_reseller,
                  useColorModeValue,
                  useColorModeValue
                )}
              </SimpleGrid>
            ) : (
              <Box py={10}>
                <Center>
                  <Text>Tidak terdapat reseller </Text>
                </Center>
                <Center>
                  <Text textTransform={'lowercase'}>
                    di provinsi {location}
                  </Text>
                </Center>
              </Box>
            )}
          </>
        )}
      </Flex>
    </VStack>
  );
};

export default MLocationReseller;
