/* eslint-disable react-hooks/rules-of-hooks */
// ** import Chakra UI
import {
  Text,
  Flex,
  Spacer,
  HStack,
  Box,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';

// ** import React
import React from 'react'

// ** import third package
import { Icon } from '@iconify/react';
import { Link as RouteLink } from "react-router-dom";


const MProfitItem = ({ data }) => {
  return (
    <Box
      my={2}
      borderRadius={'4px'}
      bgColor={'rgba(255, 255, 255, 0.3)'}
      w={useBreakpointValue({ base: "full", sm: "35rem" })}
      h={useBreakpointValue({ base: 'full', sm: "40rem" })}
      style={{
        backdropFilter: "blur(5px)"
      }}
    >
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDir={'column'}
        py={'3em'}
        px={useBreakpointValue({ base: '2rem', sm: "5rem" })}
        h="full"
        w="full"
      >
        {/* Header */}
        <Box
          w={'full'}
        >
          <Text
            color={'white'}
            textAlign={'center'}
            fontSize={useBreakpointValue({ base: 'xs', sm: "md" })}
          >
            {data.title}
          </Text>
          <Text
            color={'white'}
            textAlign={'center'}
            fontWeight={'bold'}
            fontSize={useBreakpointValue({ base: 'xl', sm: "4xl" })}
          >
            {data.tag}
          </Text>
        </Box>

        {/* Content */}
        <Spacer />

        <Box
          w={'full'}
          h={useBreakpointValue({ base: '15rem', sm: "full" })}
          display={'flex'}
          flexDir={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box>
            {
              data.profit.map((p, idx) => {
                return (
                  <HStack
                    alignItems={'start'}
                    key={idx}
                  >
                    <Icon icon="ci:check-bold" color="white" style={{ fontSize: '24px' }} />
                    <Text
                      style={{ padding: 0 }}
                      fontSize={useBreakpointValue({ base: 'sm', sm: "xl" })}
                      color={'white'}
                    >
                      {p}
                    </Text>
                  </HStack>
                )
              })
            }
          </Box>
        </Box>

        {/* Footer */}
        <Spacer />

        <Box
          w={'full'}
        >
          {
            data.button.link !== '' ? (
              <RouteLink to={data.button.link}>
                <Button
                  width={'full'}
                  variant="solid"
                  bg='#F15A25'
                  color='white'
                  fontWeight='bold'
                  _hover={{ bg: "orange.700", borderColor: "orange.700" }}
                  textTransform={'uppercase'}
                >
                  {data.button.name}
                </Button>
              </RouteLink>
            ) : (
              <Button
                width={'full'}
                variant="solid"
                bg='#F15A25'
                color='white'
                fontWeight='bold'
                _hover={{ bg: "orange.700", borderColor: "orange.700" }}
                textTransform={'uppercase'}
                onClick={data.button.open}
              >
                {data.button.name}
              </Button>
            )
          }
        </Box>
      </Flex >
    </Box >
  )
}

export default MProfitItem
