// ** Import Chakra UI
import {
  Box,
  Heading,
  Text,
  Stack,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';

// ** third package
import { Icon } from '@iconify/react';

// ** react
import React from 'react';

const MValueItem = ({ data }) => {
  const Value = ({ children }) => {
    return (
      <Box
        w={useBreakpointValue({ base: 'full', md: '23rem' })}
        bgColor={'#FFF4F0'}
      >
        {children}
      </Box>
    );
  };

  const ValueContent = ({ children }) => {
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={8}
        align={'center'}
        pos={'relative'}
        bgColor={'#FFF4F0'}
      >
        {children}
      </Stack>
    );
  };

  const ValueHeading = ({ children }) => {
    return (
      <Heading textAlign={'center'} as={'h3'} fontSize={'xl'}>
        {children}
      </Heading>
    );
  };

  const TestimonialText = ({ children }) => {
    return (
      <Box
        h="10vh"
        w="auto"
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Text
          color={useColorModeValue('gray.600', 'gray.400')}
          fontSize={'sm'}
          textAlign={'center'}
        >
          {children}
        </Text>
      </Box>
    );
  };

  const ValueIcon = ({ icon }) => {
    return <Icon icon={icon} style={{ fontSize: '40px', color: '#F15A25' }} />;
  };

  return (
    <Value>
      <ValueContent>
        <ValueIcon icon={data.icon} />
        <ValueHeading>{data.heading}</ValueHeading>
        <TestimonialText>{data.text}</TestimonialText>
        {/* <ValueLink redirect={data.redirect}>See More</ValueLink> */}
      </ValueContent>
    </Value>
  );
};

export default MValueItem;
