// ** import chakra ui
import { Box, Link } from '@chakra-ui/react';

// ** react
import React from 'react';

// ** Import third package
import { Icon } from '@iconify/react';

const MWhatsappWidget = () => {
  return (
    <Box position={'fixed'} zIndex={3} bottom={'30px'} right={'30px'}>
      <Link
        href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WA_NUMBER}&text=Saya%20ingin%20bertanya%20mengenai%20produk%20dari%20orthophysio.`}
        target={'_blank'}
      >
        <Icon
          icon="logos:whatsapp-icon"
          style={{ fontSize: '42px', cursor: 'pointer' }}
        />
      </Link>
    </Box>
  );
};

export default MWhatsappWidget;
