// ** import chakra ui
import {
  Box,
  Button,
  Link,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useBreakpointValue,
  ListItem,
  UnorderedList
} from '@chakra-ui/react';

// ** react
import React from 'react';

// ** import third package
import { Icon } from '@iconify/react';

const MModalDaftarReseller = ({ open, close, areOpen }) => {
  return (
    <Modal isOpen={areOpen} onClose={close} size={useBreakpointValue({ base: 'sm', sm: '3xl' })}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Icon icon="fe:warning" fontSize={'32px'} color={'orange'} />
            <Text
              fontSize={useBreakpointValue({ base: "sm", sm: "2xl" })}
            >Syarat & Kententuan menjadi reseller</Text>
            <ModalCloseButton />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <VStack
            alignItems={'start'}
          >
            <Text
              fontSize={useBreakpointValue({ base: "sm", sm: "2xl" })}
              color={'gray.500'}
            >
              Syarat & Kententuan :
            </Text>
            <Box
              px={2}
            >
              <UnorderedList color={'gray.500'}>
                <ListItem>Melakukan pembelian pertama minimal 2 pcs untuk semua item</ListItem>
                <ListItem>Konfirmasi pembelian dengan menekan tombol dibawah</ListItem>
              </UnorderedList>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Link
            href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WA_NUMBER}&text=Hai%20min,%20saya%20tertarik%20dan%20ingin%20menjadi%20reseller%20,%20mohon%20infonya%20dong`}
            target={"_blank"}
            style={{ textDecoration: 'none' }}
            w="full"
          >

            <Button
              variant="solid"
              bg="#F15A25"
              color="white"
              fontWeight="bold"
              _hover={{ bg: 'orange.700', borderColor: 'orange.700' }}
              w="full"
            >
              Daftar jadi reseller
            </Button>
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal >
  )
}

export default MModalDaftarReseller
