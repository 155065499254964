// ** import chakra ui
import { Box, Text, VStack } from '@chakra-ui/react';

// ** import react
import React from 'react'

// ** import third package
import { Link as RouteLink } from "react-router-dom";

const ErrorPage = () => {
  return (
    <Box
      w="full"
      h="100vh"
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <VStack>
        <Text
          fontSize={'4xl'}
          fontWeight={'bold'}
        >
          404
        </Text>
        <Text>Page Not Found</Text>
        <RouteLink to="/">
          <Text
            fontSize={'sm'}
            color={'#2A4365'}
            fontWeight={'semibold'}
            _hover={{ color: '#F15A25', cursor: "pointer" }}
          >Back to Home</Text>
        </RouteLink>
      </VStack>
    </Box>
  )
}

export default ErrorPage
