// ** import chakraUI
import {
  Container,
  Flex,
  VStack,
  Heading,
  Text,
  Button,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';

// ** import third package
import { Link as RouteLink } from 'react-router-dom';
import { heroImage } from '../../assets/images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// ** import react pacckage
import React from 'react';

const MHero2 = () => {
  return (
    <Container maxW="container.xl">
      <Flex
        flexDir={['column', 'row']}
        w={'full'}
        h={useBreakpointValue({ base: 'full', md: '80vh' })}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={6}
      >
        <Box>
          <VStack alignItems={['center', 'start']} spacing="45px">
            <Heading
              textAlign={['center', 'start']}
              size={useBreakpointValue({ base: 'xl', md: '2xl' })}
            >
              Alat bantu kesehatan untukmu
            </Heading>
            <Text textAlign={['center', 'start']} fontSize={'2xl'}>
              Dapatkan alat bantu kesehatan anda dengan mudah di
              <Text color={'#F15A25'} fontWeight={'bold'}>
                orthophysiobrace
              </Text>
            </Text>
            <RouteLink to="/produk">
              <Button
                variant="solid"
                bg="#F15A25"
                color="white"
                fontWeight="bold"
                _hover={{ bg: 'orange.700', borderColor: 'orange.700' }}
              >
                Jelajahi produk
              </Button>
            </RouteLink>
          </VStack>
        </Box>
        <Box>
          <LazyLoadImage src={heroImage} alt="Dan Abramov" effect="blur" />
        </Box>
      </Flex>
    </Container>
  );
};

export default MHero2;
