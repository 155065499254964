// ** import Chakra UI
import {
  Box,
  Container,
  Link,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  Image,
  HStack,
  Flex,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';

// ** Import React
import React from 'react';
import { Logo_footer } from '../../assets/images';

const MFooter = () => {
  const AddLogo = props => {
    return (
      <HStack spacing={6}>
        <Image src={Logo_footer} height={12} {...props} />
        <Box w="14rem">
          <Text fontWeight={'bold'} fontSize={'lg'} textTransform={'uppercase'}>
            Orthophysio Sports & branching
          </Text>
        </Box>
      </HStack>
    );
  };

  const ListHeader = ({ children }) => {
    return (
      <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
        {children}
      </Text>
    );
  };

  return (
    <Box bg={'#424242'} color={'gray.100'}>
      <Container as={Stack} maxW="container.xl" py={10}>
        <Flex flexDir={useBreakpointValue({ base: 'column', sm: 'row' })}>
          <Box>
            <Stack spacing={6}>
              <Box>
                <AddLogo color={useColorModeValue('gray.700', 'white')} />
              </Box>
              <Box>
                <Text
                  fontSize={'sm'}
                  textAlign={useBreakpointValue({
                    base: 'center',
                    sm: 'start',
                  })}
                >
                  {/* PT. Klinik Fisioterapi Home care <br /> */}
                  Kompleks Griya Kenari Daya Blok B12
                  <br />
                  JL PACCERAKKANG DAYA,
                  <br />
                  KOTA MAKASSAR PROVINSI SULAWESI SELATAN
                </Text>
              </Box>
              <Link
                href="#"
                w="full"
                onClick={e => {
                  window.location = 'mailto:no-reply@example.com';
                  e.preventDefault();
                }}
              >
                <Button
                  w="full"
                  variant="solid"
                  bg="#F15A25"
                  color="white"
                  fontWeight="bold"
                  _hover={{ bg: 'orange.700', borderColor: 'orange.700' }}
                >
                  HUBUNGI KAMI
                </Button>
              </Link>
            </Stack>
          </Box>
          <Spacer />
          <HStack my={5}>
            <Stack align={'flex-start'}>
              <ListHeader>Produk</ListHeader>
              <Link href={'#'}>Lihat Produk</Link>
              <Link href={'#'}>Jadi Reseller</Link>
              <Link href={'#'}>Syarat & Ketentuan</Link>
            </Stack>
            <Spacer />
            <Stack align={'flex-start'}>
              <ListHeader>Layanan Pelanggan</ListHeader>
              <Link href={'#'}>{process.env.REACT_APP_WA_NUMBER}</Link>
              <Link
                href={'#'}
                onClick={e => {
                  window.location = `mailto:${process.env.REACT_APP_MAIL}`;
                  e.preventDefault();
                }}
              >
                {process.env.REACT_APP_MAIL}
              </Link>
              <Link href={'#'}>Laporkan Gangguan</Link>
            </Stack>
          </HStack>
        </Flex>
        <HStack justifyContent={'center'} alignItems={'center'}>
          <Text>Copyright</Text>
          <Text
            as={'a'}
            href="https://coredinatelab.id/"
            target={'_blank'}
            fontWeight={'bold'}
          >
            {' '}
            © 2022 Coredinate Lab.
          </Text>
        </HStack>
      </Container>
    </Box>
  );
};

export default MFooter;
