// ** import chakra ui
import { Box } from '@chakra-ui/react';

// ** import components
import MHeader from '../components/molecules/MHeader';
import MHero2 from '../components/molecules/MHero2';
import MValue from '../components/molecules/MValue';
import MFooter from '../components/molecules/MFooter';
import MKatalog from '../components/molecules/MKatalog';
import MLocationReseller from '../components/molecules/MLocationReseller';
import MProfit from '../components/molecules/MProfit';
import onContentIndexClick from '../helpers/scroll';

// ** react
import React, { useEffect, useState } from 'react';

// ** import third package
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const Home = ({ open }) => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [resellers, setResellers] = useState([]);

  useEffect(() => {
    if (location.state !== null) {
      onContentIndexClick(location.state.id);
    }
  }, [location.state]);

  const fetchProduct = () => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}api/products-data/`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        crossDomain: true,
        validateStatus: false,
      })
      .then(response => {
        setProducts(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchReseller = () => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}api/resellers-data/`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        crossDomain: true,
        validateStatus: false,
      })
      .then(response => {
        setResellers(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchProduct();
    fetchReseller();
  }, []);

  return (
    <Box>
      <MHeader open={open} />
      <MHero2 />
      <div id="tentangKami">
        <MValue />
      </div>
      {products.length !== 0 && <MKatalog data={products} />}
      {resellers.length !== 0 && <MLocationReseller resellers={resellers} />}
      <MProfit open={open} />
      <MFooter />
    </Box>
  );
};

export default Home;
