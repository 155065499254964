/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
// ** import Chakra UI
import {
  Container,
  Spinner,
  Spacer,
  Stack,
  Text,
  Flex,
  VStack,
  HStack,
  Box,
  Link,
  Button,
  Image,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Divider,
} from '@chakra-ui/react';

// ** import React
import React, { useState, useEffect } from 'react';

//  ** import third package
import axios from 'axios';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { Icon } from '@iconify/react';
import { Link as RouteLink, useParams } from 'react-router-dom';
import { formatRupiah, gToKg, truncate } from '../../helpers/utilitys';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { noTelp } from '../../data/noTelpAdmin';
import 'sweetalert2/src/sweetalert2.scss';

const MDetailProduct = () => {
  const params = useParams();
  const [currentImage, setCurrentImage] = useState('');
  const [stock, setStock] = useState(0);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [copy, setCopied] = useState(false);
  const [userId, setUserId] = useState('');
  const fetchIdProduct = () => {
    axios
      .get(
        `${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}api/products-data-detail/${params.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          crossDomain: true,
          validateStatus: false,
        }
      )
      .then(response => {
        setData(response.data.data);
        setCurrentImage(
          `${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}/${response.data.data.image}`
        );
        if (response.data.data.detail.length !== 1) {
          let sum = 0;
          for (let key in response.data.data.detail) {
            sum += parseInt(response.data.data.detail[key].stock);
          }
          setStock(sum);
        } else {
          let sum = response.data.data.detail[0].stock;
          setStock(sum);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const user = searchParams.get('user');

    if (user !== null) setUserId(user);
  }, []);

  const waNoTelp = () => {
    if (userId !== '' && userId <= noTelp.length - 1) {
      return noTelp[userId];
    } else {
      return noTelp[0];
    }
  };

  const funcCopy = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    Swal.fire({
      title: 'Copied!',
      text: `copied the text ${el.value}`,
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  useEffect(() => {
    async function fetchdata() {
      await setIsLoading(true);
      await window.scrollTo(0, 0);
      await fetchIdProduct();
      await setIsLoading(false);
    }
    fetchdata();
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        Object.keys(data).length !== 0 && (
          <Flex
            flexDir={'column'}
            w={'full'}
            h={'full'}
            py={['3rem', '5rem']}
            bgColor={'#EFEFEF'}
          >
            <Container maxW="container.xl">
              <Flex fontSize={'md'} color={'#F15A25'} py={4}>
                <RouteLink to="/">
                  <Text mx={'1'}>Home</Text>
                </RouteLink>
                {`>`}
                <RouteLink to="/produk">
                  <Text mx={'1'}>Produk</Text>
                </RouteLink>
                {`>`}
                <Text mx="1" fontWeight={'bold'}>
                  {truncate(data.name, 20)}
                </Text>
              </Flex>
              <VStack
                backgroundColor={'#FFFFFF'}
                boxShadow="2xl"
                borderRadius={4}
                h="full"
                w="full"
                alignItems={'start'}
              >
                <Flex flexDir={'row'} w="full">
                  <Flex padding={10} w="full" flexDir={['column', 'row']}>
                    <Box
                      w={['full', '23rem']}
                      h={'23rem'}
                      mx={3}
                      display={'flex'}
                      // justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <VStack>
                        <Zoom>
                          <Image
                            src={currentImage}
                            alt={`Picture of ${data.name}`}
                            shadow="sm"
                            width={'fit-content'}
                            height={'18rem'}
                            border="1px"
                            borderColor="gray.200"
                            objectFit="cover"
                          />
                        </Zoom>
                        {data.product_image.length !== 0 && (
                          <HStack>
                            <Box
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                setCurrentImage(
                                  `${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}/${data.image}`
                                )
                              }
                            >
                              <Image
                                src={`${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}/${data.image}`}
                                alt={`Picture of ${data.name}`}
                                shadow="sm"
                                width={'5rem'}
                                height={'5rem'}
                                border="1px"
                                borderColor="gray.200"
                                objectFit="cover"
                              />
                            </Box>
                            {data.product_image.map((d, idx) => (
                              <Box
                                key={idx}
                                onClick={() =>
                                  setCurrentImage(
                                    `${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}/${d.image}`
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                <Image
                                  src={`${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}/${d.image}`}
                                  alt={`Picture of ${d.product_id}`}
                                  shadow="sm"
                                  width={'5rem'}
                                  height={'5rem'}
                                  border="1px"
                                  borderColor="gray.200"
                                  objectFit="cover"
                                />
                              </Box>
                            ))}
                          </HStack>
                        )}
                      </VStack>
                    </Box>
                    <Spacer />
                    <Box
                      marginTop={['2rem', '0px']}
                      w={'50vw'}
                      h={['full', '20vw']}
                    >
                      <Stack>
                        <Text
                          fontSize={'3xl'}
                          fontWeight={'bold'}
                          textTransform={'capitalize'}
                          borderBottom={'1px'}
                          borderColor={'gray.200'}
                        >
                          {data.name}
                        </Text>
                        <Box
                          backgroundColor={'red'}
                          color={'white'}
                          w="80px"
                          textAlign={'center'}
                          borderRadius={4}
                        >
                          <Text fontSize={'sm'} fontWeight={'semibold'}>
                            Mulai dari
                          </Text>
                        </Box>
                        <Text
                          fontSize={['3xl', '5xl']}
                          color={'red'}
                          fontWeight={'bold'}
                        >
                          {data.detail.length !== 1
                            ? `
                          ${formatRupiah(data.detail[0].price)}
                          -
                          ${formatRupiah(
                            data.detail[data.detail.length - 1].price
                          )}
                          `
                            : `${formatRupiah(data.detail[0].price)}`}
                        </Text>
                        <HStack>
                          <Text fontSize={'lg'} fontWeight={'semibold'}>
                            Jumlah Barang
                          </Text>
                          <Text
                            fontSize={'lg'}
                            fontWeight={'semibold'}
                            color={'gray.600'}
                          >
                            (Pcs)
                          </Text>
                        </HStack>
                        <NumberInput defaultValue={1} min={1} max={stock}>
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <HStack>
                          <Stack textAlign={'start'} w="100px">
                            <Text color={'gray.600'} fontSize={'sm'}>
                              Stock
                            </Text>
                            <Text color={'gray.600'} fontWeight={'bold'}>
                              {stock}
                            </Text>
                          </Stack>
                          <Stack textAlign={'start'} w="100px">
                            <Text color={'gray.600'} fontSize={'sm'}>
                              Berat
                            </Text>
                            <Text color={'gray.600'} fontWeight={'bold'}>
                              {gToKg(data.detail[0].stock)}
                            </Text>
                          </Stack>
                          <Stack textAlign={'start'} w="100px">
                            <Text color={'gray.600'} fontSize={'sm'}>
                              Ukuran (cm)
                            </Text>
                            {data.detail.length !== 1 ? (
                              data.detail.map((d, idx) => (
                                <Text key={idx}>{d.type}</Text>
                              ))
                            ) : (
                              <Text>{data.detail[0].type}</Text>
                            )}
                          </Stack>
                        </HStack>
                        <Flex
                          w={'full'}
                          h="full"
                          py={'1rem'}
                          flexDir={['column', 'row']}
                        >
                          <Link
                            href={`https://api.whatsapp.com/send?phone=${waNoTelp()}&text=
                                Hai%20min,%20saya%20ingin%20bertanya%20mengenai%20produk%20${
                                  data.name
                                }%20seharga%20${formatRupiah(
                              data.detail[0].price
                            )}%20yang%20dijual%20di%20orthophysiobrace.com%20,%20terima%20kasih%20banyak%20sebelumnya`}
                            target={'_blank'}
                            style={{ textDecoration: 'none' }}
                            w="full"
                            my="10px"
                            mx="10px"
                          >
                            <Button
                              w="full"
                              variant="solid"
                              bg="#F15A25"
                              color="white"
                              fontWeight="bold"
                              _hover={{
                                bg: 'orange.700',
                                borderColor: 'orange.700',
                              }}
                            >
                              <Icon
                                icon="logos:whatsapp"
                                style={{ fontSize: '24px', margin: '10px' }}
                              />
                              <Text>PESAN SEKARANG</Text>
                            </Button>
                          </Link>

                          <Button
                            w="full"
                            my="10px"
                            mx="10px"
                            variant="solid"
                            bg="gray.500"
                            color="white"
                            fontWeight="bold"
                            _hover={{
                              bg: 'orange.700',
                              borderColor: 'orange.700',
                            }}
                            onClick={() => funcCopy()}
                          >
                            <Icon
                              icon="bi:share-fill"
                              color="white"
                              style={{ fontSize: '24px', margin: '10px' }}
                            />
                            <Text>BAGIKAN</Text>
                          </Button>
                        </Flex>
                      </Stack>
                    </Box>
                  </Flex>
                </Flex>
                <VStack padding={10} alignItems={'start'}>
                  <Text fontSize={'xl'} fontWeight={'bold'} color={'#F15A25'}>
                    Deskripsi
                  </Text>
                  <Divider />
                  <Text>{data.description}</Text>
                </VStack>
              </VStack>
            </Container>
          </Flex>
        )
      )}
    </Box>
  );
};

export default MDetailProduct;
