// ** import chakra ui
import {
  Flex,
  Text,
  Box,
  SimpleGrid,
  useBreakpointValue,
  Link,
} from '@chakra-ui/react';

// ** import React
import React from 'react';

// ** import third package
import { Icon } from '@iconify/react';

const MKontak = () => {
  const data = [
    {
      icon: 'fluent:mail-24-filled',
      name: 'email',
      value: 'no-reply@example.com',
    },
    {
      icon: 'gridicons:phone',
      name: 'phone/WA',
      value: '0812-4500-9123',
    },
    {
      icon: 'ant-design:instagram-filled',
      name: 'instagram',
      value: 'indophy7',
      url: 'https://www.instagram.com/indophy7/',
    },
  ];

  const ItemKontak = ({ data }) => {
    return (
      <>
        {data.name === 'email' ? (
          <Box
            display={'flex'}
            flexDir={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            _hover={{ cursor: 'pointer' }}
            p={5}
            borderRadius={'5'}
            onClick={e => {
              window.location = 'mailto:no-reply@example.com';
              e.preventDefault();
            }}
          >
            <Box
              backgroundColor={'gray.400'}
              width={'6rem'}
              height={'6rem'}
              borderRadius={'full'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Icon
                icon={data.icon}
                color="white"
                style={{ fontSize: '64px' }}
              />
            </Box>
            <Text textTransform={'capitalize'} fontWeight={'bold'} mt={2}>
              {data.name}
            </Text>
            <Text textTransform={'capitalize'}>{data.value}</Text>
          </Box>
        ) : (
          <Link
            href={
              data.name !== 'instagram'
                ? `https://api.whatsapp.com/send?phone=${data.value}&text=Saya%20ingin%20bertanya%20mengenai%20produk%20dari%20orthophysio.`
                : data.url
            }
            target={'_blank'}
            style={{ textDecoration: 'none' }}
            isExternal
          >
            <Box
              display={'flex'}
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              _hover={{ cursor: 'pointer' }}
              p={5}
              borderRadius={'5'}
            >
              <Box
                backgroundColor={'gray.400'}
                width={'6rem'}
                height={'6rem'}
                borderRadius={'full'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Icon
                  icon={data.icon}
                  color="white"
                  style={{ fontSize: '64px' }}
                />
              </Box>
              <Text textTransform={'capitalize'} fontWeight={'bold'} mt={2}>
                {data.name}
              </Text>
              <Text textTransform={'capitalize'}>{data.value}</Text>
            </Box>
          </Link>
        )}
      </>
    );
  };

  return (
    <Flex
      flexDir={'column'}
      w={'full'}
      h={useBreakpointValue({ base: 'full', sm: '80vh' })}
      py={useBreakpointValue({ base: '3rem', sm: '5rem' })}
      textAlign={'center'}
      justifyContent={'center'}
      alignItems={'center'}
      bgColor={'#FFFFFF'}
    >
      <Text fontSize={'5xl'} fontWeight={'bold'}>
        Hubungi Orthophysio
      </Text>
      <Text fontSize={'md'} color={'gray'} my={5}>
        Hubungi kami jika anda butuh bantuan dan ingin mengetahui info terbaru
      </Text>
      <SimpleGrid
        py={20}
        columns={useBreakpointValue({ base: 1, sm: 3 })}
        spacingX={'0rem'}
        spacingY="100px"
      >
        {data.map((d, idx) => {
          return <ItemKontak key={idx} data={d} />;
        })}
      </SimpleGrid>
    </Flex>
  );
};

export default MKontak;
