// ** import chakra ui
import { Box } from '@chakra-ui/react';

// ** import Components
import MHeader from '../components/molecules/MHeader'
import MFooter from '../components/molecules/MFooter'
import MKontak from '../components/molecules/MKontak'

// ** import React
import React from 'react'

const Kontak = ({ open }) => {
  return (
    <Box>
      <MHeader open={open} />
      <MKontak />
      <MFooter />
    </Box>
  )
}

export default Kontak
