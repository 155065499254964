// ** Import Chakra UI
import {
  Box,
  Stack,
  Spacer,
  Image,
  Flex,
  Button,
  useDisclosure,
  Container,
  Text,
  Link,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

// ** import Assets
import { Logo } from '../../assets/images';

// ** import third part
import { useLocation, Link as RouteLink } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll, scroller } from 'react-scroll'

// ** react
import React, { useState } from 'react';

const MHeader = ({ props, open }) => {
  let location = useLocation();
  let pathname = location.pathname;

  const [acHome] = useState(pathname === '/' ? true : false);
  const [acProduk] = useState(pathname === '/produk' ? true : false);
  const [acKontak] = useState(pathname === '/kontak' ? true : false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen());

  const scrollTo = (id) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: id
    })
  }

  return (
    <Container maxW="container.xl">
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding={6}
        bg="white"
        {...props}
      >
        <RouteLink to="/" align="center" mr={5}>
          <Image src={Logo} />
        </RouteLink>
        <Spacer />
        <Box display={{ base: 'block', md: 'none' }} onClick={handleToggle}>
          <HamburgerIcon />
        </Box>

        <Stack
          spacing="24px"
          direction={{ base: 'column', md: 'row' }}
          display={{ base: isOpen ? 'block' : 'none', md: 'flex' }}
          width={{ base: 'full', md: 'auto' }}
          alignItems="center"
          flexGrow={1}
          mt={{ base: 4, md: 0 }}
        >
          <RouteLink to="/" >
            <Text
              fontWeight={acHome ? 'bold' : 'medium'}
              color={acHome ? '#F15A25' : 'gray.800'}
              _hover={{ color: '#F15A25', cursor: 'pointer' }}
            >
              Beranda
            </Text>
          </RouteLink>
          <RouteLink to="/" state={{ id: 'tentangKami' }}>
            <Text
              fontWeight="medium"
              color="gray.800"
              _hover={{ color: '#F15A25', cursor: 'pointer' }}
            >
              Tentang Kami
            </Text>
          </RouteLink>
          <RouteLink to="/produk">
            <Text
              fontWeight={acProduk ? 'bold' : 'medium'}
              color={acProduk ? '#F15A25' : 'gray.800'}
              _hover={{ color: '#F15A25', cursor: 'pointer' }}
            >
              Produk
            </Text>
          </RouteLink>
          <RouteLink to="/kontak">
            <Text
              fontWeight={acKontak ? 'bold' : 'medium'}
              color={acKontak ? '#F15A25' : 'gray.800'}
              _hover={{ color: '#F15A25', cursor: 'pointer' }}
            >
              Kontak
            </Text>
          </RouteLink>
        </Stack>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
          mt={{ base: 4, md: 0 }}
          spacing="24px"
        >
          <Link
            href="https://dashboard.orthophysiobrace.com/admin/login"
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="ghost"
              color="#F15A25"
              fontWeight="bold"
              _hover={{ bg: '#F15A25', color: 'white' }}
            >
              Masuk
            </Button>
          </Link>
          <Button
            onClick={open}
            variant="solid"
            bg="#F15A25"
            color="white"
            fontWeight="bold"
            _hover={{ bg: 'orange.700', borderColor: 'orange.700' }}
          >
            Daftar Reseller!
          </Button>
        </Stack>
      </Flex>
    </Container >
  );
};

export default MHeader;
