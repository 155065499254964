// ** Import Chakra UI
import {
  Flex,
  Heading,
  Text,
  Stack,
  VStack,
  Container,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';

// ** import Components
import MValueItem from './MValueItem';

// ** react
import React from 'react';

const MValue = () => {
  const dummy = [
    {
      _id: 1,
      icon: 'carbon:order-details',
      heading: 'Pemesanan Mudah',
      text: 'Cukup dengan menghubungi kami, atau reseller terdekat dikota anda',
      redirect: '',
    },
    {
      _id: 2,
      icon: 'icon-park-outline:ad-product',
      heading: 'Keuntungan Transaksi',
      text: 'Semakin sering bertransaksi maka harga yang anda dapatkan akan semakin murah',
      redirect: '',
    },
    {
      _id: 3,
      icon: 'mdi:basket-check-outline',
      heading: 'Kualitas Produk',
      text: 'Produk yang dipasarkan oleh kami, sudah banyak digunakan oleh dokter orthopedi, saraf, dan fisioterapi diindonesia.',
      redirect: '',
    },
  ];

  return (
    <Box bgColor={'#FFF4F0'}>
      <Container maxW="container.xl">
        <Flex
          flexDir={'column'}
          w={'full'}
          h={useBreakpointValue({ base: 'full', md: '80vh' })}
          justifyContent={'center'}
          alignItems={'center'}
          padding={6}
        >
          <VStack justify={'center'} spacing={20}>
            <Stack spacing={5} align={'center'}>
              <Heading color={'gray.800'} align="center">
                Dapatkan kemudahan alat bantu kesehatan!
              </Heading>
              <Text color={'gray.600'} align={'center'}>
                Kami menyediakan alat bantu kesehatan seperti Lumbal Korset,
                Thorakal Brace, Knee Brace, dan beberapa produk alat bantu
                kesehatan yang bisa anda dapatkan di Orthophsio brace.{' '}
              </Text>
            </Stack>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: 10, md: 4, lg: 10 }}
            >
              {dummy.map((data, idx) => {
                return <MValueItem data={data} key={idx} />;
              })}
            </Stack>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default MValue;
