// ** import chakra ui
import { Box } from '@chakra-ui/react';

// ** import components
import MHeader from '../components/molecules/MHeader';
import MFooter from '../components/molecules/MFooter';
import MProduct from '../components/molecules/MProduct';

// ** react
import React, { useState, useEffect } from 'react';

// ** import third package
import axios from 'axios';

const Katalog = ({ open }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchProduct = async () => {
    await setIsLoading(true)
    await axios
      .get(`${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}api/products-data/`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        crossDomain: true,
        validateStatus: false,
      })
      .then(response => {
        // console.log(response);
        setProducts(response.data.data);
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProduct();
  }, []);
  return (
    <Box>
      <MHeader open={open} />
      <MProduct data={products} isLoading={isLoading} />
      <MFooter />
    </Box>
  );
};

export default Katalog;
