// ** import Chakra UI
import {
  Flex,
  Box,
  Image,
  useColorModeValue,
  Text,
  HStack,
} from '@chakra-ui/react';

//  ** import third package
import { Link as RouteLink } from 'react-router-dom';

// ** import React
import React from 'react';
import { formatRupiah, truncate } from '../../helpers/utilitys';

const MItemKatalog = ({ data }) => {
  return (
    <RouteLink to={`/produk/${data.id}`} state={{ data }}>
      <Box
        bg={useColorModeValue('white', 'gray.800')}
        maxW="sm"
        width={['11rem', '18rem']}
        h={['18rem', 'full']}
        borderWidth="1px"
        rounded="lg"
        shadow="lg"
        m={2}
        borderTop={4}
      >
        <Image
          src={`${process.env.REACT_APP_DOMAIN_ORTHOPHYSIO}/${data.image}`}
          alt={`Picture of ${data.name}`}
          shadow="sm"
          width={'full'}
          rounded="lg"
          height={['10rem', '18rem']}
          objectFit="cover"
        />
        <Box p="4">
          <Flex mt="1" alignContent="center">
            <Text
              fontSize={['xs', 'md']}
              color={'gray.800'}
              fontWeight={700}
              align="center"
              style={{ textTransform: 'uppercase' }}
            >
              {truncate(data.name, 25)}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignContent="center">
            <HStack align={'end'}>
              <Text fontSize={['lg', '3xl']} color={'#F15A25'} fontWeight={700}>
                {data.detail.length !== 1
                  ? `
                ${formatRupiah(data.detail[0].price)} 
                - 
                ${formatRupiah(data.detail[data.detail.length - 1].price)}
                `
                  : `${formatRupiah(data.detail[0].price)}`}
              </Text>
              <Text
                fontSize={'md'}
                color={'gray.500'}
                style={{ textTransform: 'capitalize' }}
              >
                /pack
              </Text>
            </HStack>
          </Flex>
        </Box>
      </Box>
    </RouteLink>
  );
};

export default MItemKatalog;
