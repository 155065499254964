// ** import Chakra UI
import {
  Flex,
  SimpleGrid,
  Box,
  Image,
  useColorModeValue,
  Text,
  HStack,
  Spacer,
  useBreakpointValue,
  Heading,
  VStack,
  Button,
} from '@chakra-ui/react';

//  ** import third package
import { Link as RouteLink } from 'react-router-dom';

// ** import React
import React from 'react';

// ** import Component
import MItemKatalog from '../molecules/MItemKatalog';
import { formatRupiah } from '../../helpers/utilitys';
const MKatalog = ({ data }) => {
  const products = data.length !== 0 ? data.slice(0, 3) : null;

  return (
    <VStack
      bgColor={'#FFFFFF'}
      py={useBreakpointValue({ base: '3rem', sm: '5rem' })}
      w={'full'}
      h={'full'}
      flexDir={'column'}
      alignItems="center"
      justifyContent="center"
      // h={useBreakpointValue({ base: "full", md: "80vh" })}
    >
      <Heading color={'gray.800'} mb={5} align="center">
        Varian Produk Orthophsio
      </Heading>
      <Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
        <SimpleGrid
          py="2rem"
          columns={[2, 2, data.length <= 4 ? data.length : 4]}
          spacingX={['initial', '40px']}
          spacingY="20px"
        >
          {products !== null &&
            products.map((item, idx) => {
              return (
                <div key={`katalog_${idx}`}>
                  <Spacer />
                  <MItemKatalog data={item} />
                  <Spacer />
                </div>
              );
            })}
          <Box
            zIndex={1}
            bg={useColorModeValue('white', 'gray.800')}
            maxW="sm"
            width={['11rem', '18rem']}
            h={['18rem', 'full']}
            borderWidth="1px"
            rounded="lg"
            shadow="lg"
            position="relative"
            m={2}
            borderTop={4}
          >
            <Flex
              borderWidth="1px"
              rounded="lg"
              zIndex={4}
              bgColor={'rgba(17, 17, 17, 0.6)'}
              position={'absolute'}
              h={'full'}
              w={'full'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <RouteLink to="/produk">
                <Button
                  variant="solid"
                  bg="#F15A25"
                  color="white"
                  fontWeight="bold"
                  _hover={{ bg: 'orange.700', borderColor: 'orange.700' }}
                >
                  View more!
                </Button>
              </RouteLink>
            </Flex>

            <Box style={{ filter: 'blur(5px)' }}>
              <Image
                objectFit="cover"
                src={
                  'https://sportsbraces.com.au/wp-content/uploads/1970/01/11-9115-9_trom_advance_full_foam_black_hires.jpg'
                }
                alt={`Picture of https://id-test-11.slatic.net/p/964625025af55f2b56a949d980ff120c.jpg`}
                roundedTop="lg"
              />
              <Box p="4">
                <Flex
                  mt="1"
                  justifyContent="space-between"
                  alignContent="center"
                >
                  <Text
                    fontSize={['xs', 'md']}
                    color={'gray.800'}
                    fontWeight={700}
                    align="center"
                    style={{ textTransform: 'uppercase' }}
                  >
                    barang baru
                  </Text>
                </Flex>
                <SimpleGrid
                  justifyContent="space-between"
                  alignContent="center"
                >
                  <HStack align={'end'}>
                    <Text
                      fontSize={['lg', '3xl']}
                      color={'#F15A25'}
                      fontWeight={700}
                    >
                      {formatRupiah(130000)}
                    </Text>
                    <Text
                      fontSize={'md'}
                      color={'gray.500'}
                      style={{ textTransform: 'capitalize' }}
                    >
                      /pack
                    </Text>
                  </HStack>
                </SimpleGrid>
              </Box>
            </Box>
          </Box>
        </SimpleGrid>
      </Flex>
    </VStack>
  );
};

export default MKatalog;
