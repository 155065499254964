export const formatRupiah = money => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(money);
};

export const truncate = (str, len) => {
  return str.length > len ? `${str.substring(0, len - 3)}...` : str;
};

export const gToKg = (value) => {
  const valueToKG = parseFloat(value) / 1000
  return `${valueToKG} Kg`
}