// ** import chakra ui
import { Box } from '@chakra-ui/react';

// ** import components
import MHeader from '../components/molecules/MHeader'
import MFooter from '../components/molecules/MFooter';
import MDetailProduct from '../components/molecules/MDetailProduct';

// ** react
import React from 'react'

const DetailKatalog = ({ open }) => {
  return (
    <Box>
      <MHeader open={open} />
      <MDetailProduct />
      <MFooter />
    </Box>
  )
}

export default DetailKatalog
