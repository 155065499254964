// ** import Chakra UI
import {
  ChakraProvider,
  extendTheme,
  useDisclosure
} from '@chakra-ui/react';
import { Fonts } from './assets/Fonts';

// ** Router Dom
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

// ** import compoonents
import Home from './views/Home'
import Katalog from './views/Katalog'
import DetailKatalog from './views/DetailKatalog';
import ErrorPage from './views/ErrorPage';
import Kontak from './views/Kontak';
import MWhatsappWidget from './components/molecules/MWhatsappWidget';
import MModalDaftarReseller from './components/molecules/MModalDaftarReseller';
// ** react
import React from 'react';

const theme = extendTheme({
  fonts: {
    heading: "Mulish",
    body: "Mulish",
  },
})


const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Routes>
          <Route path="/" element={<Home open={onOpen} />} />
          <Route path="produk" element={<Katalog open={onOpen} />} />
          <Route path="produk/:id" element={<DetailKatalog open={onOpen} />} />
          <Route path="/kontak" element={<Kontak open={onOpen} />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <MWhatsappWidget />
        <MModalDaftarReseller open={onOpen} close={onClose} areOpen={isOpen} />
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
