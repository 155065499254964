const onContentIndexClick = (value) => {
  const element = document.getElementById(value)
  const body = document.getElementById('root')
  console.log(body.clientHeight)
  // console.log(element.clientHeight)
  if (value === "tentangKami") {
    if (element !== 615) {
      window.scrollTo({ top: 615, left: 0, behavior: "smooth" })
    } else {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}

export default onContentIndexClick