// ** import Chakra UI
import {
  Container,
  Text,
  Flex,
  HStack,
  Box,
  Button,
  useBreakpointValue,
  Stack,
} from '@chakra-ui/react';

// ** import React
import React from 'react';

// ** import third package
import { Background_section_4 } from '../../assets/images';
import AAtom from '../atoms/AArrow';
import MProfitItem from './MProfitItem';

const MProfit = ({ open }) => {
  const data = [
    {
      title: 'RESELLER ORTHOPHYSIO',
      tag: '#JadiOrthoPreneurs',
      profit: [
        'Dapatkan discount hingga 35% - 60%',
        'Produk mudah dijual & repeat order tinggi',
        'Dapatkan harga beli lebih murah',
        'Pengiriman seluruh indonesia',
        'Dapatkan keuntungan maksimal',
        'Jaminan anti rugi',
        'Produk dapat dikembalikan/ditukar',
        'Berpotensi dapat penghasilan passive income',
      ],
      button: {
        name: 'Daftar Jadi Reseller',
        link: '',
        open: open,
      },
    },
    {
      title: 'BELI PRODUK ORTHOPHYSIO',
      tag: '#JadiOrthoClient',
      profit: [
        'Beli lewat orthophysio.com',
        'Pengiriman seluruh Indonesia',
        'Produk & harga terbaik',
        'Gratis konsultasi langsung',
      ],
      button: {
        name: 'Beli Produk Orthophysio',
        link: '/produk',
        open: '',
      },
    },
  ];

  return (
    <Box
      w={'full'}
      h={'full'}
      py={useBreakpointValue({ base: '3rem', sm: '5rem' })}
      // bgRepeat="no-repeat"
      backgroundSize={'cover'}
      backgroundPosition="center"
      backgroundImage={Background_section_4}
    >
      <Container maxW="container.xl">
        <Flex flexDir={'column'}>
          <Stack>
            <Box>
              <Text
                fontSize={useBreakpointValue({ base: '2xl', sm: '5xl' })}
                color="white"
              >
                Jadi Reseller Orthophysio,
              </Text>
              <HStack>
                <Text
                  fontSize={useBreakpointValue({ base: '2xl', sm: '5xl' })}
                  color="white"
                >
                  dan
                </Text>
                <Text
                  fontWeight={'bold'}
                  fontSize={useBreakpointValue({ base: '2xl', sm: '5xl' })}
                  color={'white'}
                >
                  Dapatkan Keuntungan
                </Text>
              </HStack>
              <Text
                fontWeight={'bold'}
                fontSize={useBreakpointValue({ base: '2xl', sm: '5xl' })}
                color={'white'}
              >
                Lebih banyak!
              </Text>
            </Box>
            <Box>
              <Button
                variant="solid"
                bg="#F15A25"
                color="white"
                fontWeight="bold"
                _hover={{ bg: 'orange.700', borderColor: 'orange.700' }}
              >
                SAYA INGIN DAFTAR!
              </Button>
            </Box>
          </Stack>
          <AAtom />
          <Box>
            <Flex
              flexDir={useBreakpointValue({ base: 'column', md: 'row' })}
              h="full"
              w="full"
              justifyContent={'space-around'}
              alignItems={'center'}
            >
              {data.map((d, idx) => {
                return <MProfitItem data={d} key={idx} />;
              })}
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default MProfit;
